* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.contact-text{
  position: relative;
  /* mobile 20% */
}

body {
  font-family: Lato, Helvetica, Arial, sans-serif;
  background: #eee;
}

a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
}

.navbar-root {
  position: sticky;
  top: 0;
  z-index: 9999;
}
#navbar {
  background: white;
  color: rgb(13, 26, 38);
  position: fixed;
  top: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  z-index: 10;
}

.nav-wrapper {
  margin: auto;
  width: 90%;
}

@media (min-width: 770px) {
  .overlay-menu {
    display: none !important;
  }
  .links{
    float: right;
  }
  video {
    height:600px;
  }
}

@media (max-width: 769px) {
  #navbar {
    width: 100% !important;
  }
  video {
    height:300px;
  }
  .nav-wrapper {
    width: 90%;
  }
  .hide-max-769 {
    display: none;
  }
}
@media (max-width: 638px) {
  .nav-wrapper {
    width: 90%;
  }
}

.logo {
  float: left;
  margin-left: 28px;
  font-size: 1.5em ;
  height: 3px !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}

#navbar ul {
  display: inline-block;
  list-style: none;
  margin-top: -2px;
  text-align: right;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}

@media (orientation: landscape) {
  #navbar ul {
    display: inline-block;
  }
}

#navbar li {
  display: inline-block;
}

#navbar li a {
  color: rgb(13, 26, 38);
  display: block;
  font-size: 0.7em;
  height: 50px;
  letter-spacing: 1px;
  margin: 0 20px;
  padding: 0 4px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

#navbar li a:hover {
  color: rgb(28, 121, 184);
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

#navbar li a:before,
#navbar li a:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 1px;
  bottom: -1px;
  background: rgb(13, 26, 38);
}

#navbar li a:before {
  left: 0;
  transition: 0.5s;
}

#navbar li a:after {
  background: rgb(13, 26, 38);
  right: 0;
}

#navbar li a:hover:before {
  background: rgb(13, 26, 38);
  width: 100%;
  transition: width 0.5s cubic-bezier((0.22, 0.61, 0.36, 1));
}

#navbar li a:hover:after {
  background: transparent;
  width: 100%;
}
.a__navbar {
  text-align: center !important;
  font-size: 15px !important;
}
.btn__navbar {
  font-size: 20px;
}

img{
  width: 20%;
}
@media (max-width: 768px) {
  .menuIcon {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 15px;
    top: 20px;
    height: 23px;
    width: 27px;
    z-index: 12;
  }

  .icon-bars {
    background: rgb(13, 26, 38);
    position: absolute;
    left: 1px;
    top: 45%;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .icon-bars::before {
    background: rgb(13, 26, 38);
    content: "";
    position: absolute;
    left: 0;
    top: -8px;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars::after {
    margin-top: 0px;
    background: rgb(13, 26, 38);
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 2px;
    width: 20px;
    -webkit-transition: 0.3s width 0.4s;
    transition: 0.3s width 0.4s;
  }

  .icon-bars.overlay {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 20px;
    animation: middleBar 3s infinite 0.5s;
    -webkit-animation: middleBar 3s infinite 0.5s;
  }
  @keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes middleBar {
    0% {
      width: 0px;
    }
    50% {
      width: 20px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::before {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 10px;
    animation: topBar 3s infinite 0.2s;
    -webkit-animation: topBar 3s infinite 0s;
  }
  @keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes topBar {
    0% {
      width: 0px;
    }
    50% {
      width: 10px;
    }
    100% {
      width: 0px;
    }
  }

  .icon-bars.overlay::after {
    background: rgb(97, 114, 129);
    background: rgb(183, 199, 211);
    width: 15px;
    animation: bottomBar 3s infinite 1s;
    -webkit-animation: bottomBar 3s infinite 1s;
  }
  @keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }
  @-webkit-keyframes bottomBar {
    0% {
      width: 0px;
    }
    50% {
      width: 15px;
    }
    100% {
      width: 0px;
    }
  }

  .menuIcon.toggle .icon-bars {
    top: 5px;
    transform: translate3d(0, 5px, 0) rotate(135deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars::before {
    top: 0;
    transition-delay: 0.1s;
    opacity: 0;
  }

  .menuIcon.toggle .icon-bars::after {
    top: 10px;
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.1s;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .menuIcon.toggle .icon-bars.overlay {
    width: 20px;
    opacity: 0;
    -webkit-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
  }
}
.overlay-menu {
  background: white;
  color: rgb(13, 26, 38);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  height: 100vh;
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}
.padding {
  padding-top: 80px;
}

.padding1{
  padding-top: 60px;
}
.red{
  color: red;
}
h3{
  text-decoration: underline;
}

.center{
  text-align: center;
}
.transformx-0 {
  transform: translateX(0%) !important;
}

.overlay-menu ul,
.overlay-menu li {
  display: block;
  position: relative;
}

.overlay-menu li a {
  display: block;
  font-size: 1.8em;
  letter-spacing: 4px;
  padding: 10px 0;
  text-align: right;
  text-transform: uppercase;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  padding-left: 15px;
  padding-right: 15px;
}

.overlay-menu li a:hover,
.overlay-menu li a:active {
  color: rgb(28, 121, 184);
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
